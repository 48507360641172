<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							 <!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="活动名称" name="name">
						<a-input placeholder="请输入" v-model:value="formState.name"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="活动类型" name="type">
						<a-select placeholder="请选择" v-model:value="formState.type" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">线下活动</a-select-option>
							<a-select-option :value="2">砍价活动</a-select-option>
							<a-select-option :value="3">秒杀活动</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="状态" name="isDisabled">
						<a-select placeholder="请选择" v-model:value="formState.isDisabled" style="width: 180px;">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['operation_activity_list_add']" type="primary" @click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1300 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'type'">
							<div>{{ ['线下活动', '砍价活动', '秒杀活动'][record.type - 1] }}</div>
						</template>

						<template v-if="column.key === 'imgUrl'">
							<a-image :width="50" :src="record.imgUrl"/>
						</template>

						<template v-if="column.key === 'status'">
							<div v-if="record.isDisabled === 0">
								<div v-if="record.startTime >= currentTime">未开始</div>
								<div v-else-if="record.endTime < currentTime">已结束</div>
								<div v-else>进行中</div>
							</div>
							<div v-else>已禁用</div>
						</template>

						<template v-if="column.key === 'vaildTime'">
							<div>{{transTime(record.startTime)}} 至 {{transTime(record.endTime)}}</div>
						</template>

						<template v-if="column.key === 'isDisabled'">
							<a-tag v-if="record.isDisabled == 0" color="green">启用</a-tag>
							<a-tag v-else color="orange">禁用</a-tag>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>查看</a-menu-item>
										</div>
                    <div @click="onUrl(record)">
                      <a-menu-item>获取小程序地址</a-menu-item>
                    </div>
										<div v-permission="['operation_activity_list_disabled']" @click="onDisabled(record)" v-if="record.isDisabled == 0">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['operation_activity_list_open']" @click="onDisabled(record)" v-else>
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-if="record.isDisabled === 1 && record.endTime > currentTime" v-permission="['operation_activity_list_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-if="record.isDisabled === 1 && record.endTime > currentTime" v-permission="['operation_activity_list_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

		</a-spin>
	</div>
	<temp v-else :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import moment from 'moment';
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getActivityList, disabledActivity, deleteActivity } from '@/service/modules/advert.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				formState: {
					organizationId: 0,
					cinemaId: 0,
					type: 0,
					isDisabled: -1
				},
				showModal: false,
				list: [],
				columns: [{
					title: '影院组织',
					dataIndex: 'organizationName'
				}, {
					title: '所属影院',
					dataIndex: 'cinemaName',
				}, {
					title: '活动名称',
					dataIndex: 'name'
				}, {
					title: '活动类型',
					key: 'type'
				}, {
					title: '活动状态',
					key: 'status'
				}, {
					title: '有效期',
					key: 'vaildTime'
				}, {
					title: '排序',
					dataIndex: 'sort'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0,
				searchData: [],
				organizationList: [],
				cinemaAllList: [],
				currentTime: 0
			}
		},
		created(){
			this.currentTime = moment().unix();
			// this.getData();
			this.getOrganizationList();
		},
		methods: {
			onBack() {
				this.showModal = false;
				this.getData();
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.type = this.searchData.type ? this.searchData.type : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== -1 ? this.searchData.isDisabled : undefined;
				this.getData();
			},
			reset() {
				this.$refs.formRef.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let res = await getActivityList(postData);
					this.loading = false;
					if(res.code == 200){
						this.currentTime = moment().unix();
						this.pagination.total = res.data.totalCount;
						this.list = res.data.list;
					}
				} catch (error) {
					this.loading = false;
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onSee(val){
				this.id = val.id;
				this.isEdit = true;
				this.isSee = true;
				this.showModal = true;
			},
			onEdit(val) {
				this.id = val.id;
				this.isEdit = true;
				this.isSee = false;
				this.showModal = true;
			},
			onDisabled(val){
				const textObj = {
					1: '启用',
					0: '禁用'
				}
				let text = textObj[val.isDisabled];
				this.$confirm({
					title: 'warning',
					content: '确定' + text + '该活动吗？',
					okText: '确定',
					cancleText: '取消',
					onOk: ()=>{
						let postData = {
							isDisabled: val.isDisabled === 0 ? 1 : 0,
							id: val.id
						};
						this.disabledActivity(postData);
					}
				})
			},
      onUrl(record) {
        // TODO 'pages/enterIndex/index?id=11&cId=2&sId=2&path=pages/goods/activityDetail'
      },
			async disabledActivity(data){
				let ret = await disabledActivity(data);
				if (ret.code === 200) {
					this.getData();
					if (data.isDisabled === 1) {
						this.$message.success('禁用成功');
					} else {
						this.$message.success('启用成功');
					}
				}
			},
			onDelete(val){
				this.$confirm({
					title: 'warning',
					content: '确定删除该活动吗？',
					okText: '确定',
					cancelText: '取消',
					onOk: ()=>{
						let postData = {
							id: val.id
						}
						this.deleteActivity(postData);
					}
				})
			},
			async deleteActivity(postData){
				let res = await deleteActivity(postData);
				if(res.code == 200){
					this.getData();
					this.$message.success('删除成功');
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
