<template>
	<div>
		<a-spin :spinning="loading">
		<Header :title="isEdit ? '修改活动' : '新增活动'" @back="onBack"></Header>
		<a-form style="margin-top: 30px;" ref="formRef" scrollToFirstError name="form" :labelCol="{span: 5, xxl: 4}"
			:wrapperCol="{span: 16, xxl: 15 }"  @finish="onSubmit" :model="formState">

            <a-form-item label="影院组织" name="organizationId" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-select :disabled="isSee" v-model:value="formState.organizationId" placeholder="请选择影院组织" @change="(id) => getAllCinemaList(id, true)">
                    <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="所属影院" name="cinemaId" :rules="[{required: true, message: '必选项不允许为空'}]">
                <a-select :disabled="isSee" v-model:value="formState.cinemaId" placeholder="请选择所属影院" @change="onClear">
					<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="活动名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-input :disabled="isSee" v-model:value="formState.name" placeholder="请输入活动名称"></a-input>
            </a-form-item>

			<a-form-item label="活动列表图" name="imgUrl" extra="图片只能上传一张，建议比例:690*300，大小不能超过2MB"
						 :rules="[{required: true, message: '请上传至少一张图片'}]">
				<a-upload :disabled="isSee" v-model:file-list="fileList1" name="file" list-type="picture-card"
						  class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
						  @change="(file) => handleChange(file, 2)" multiple
						  @remove="(info) => onDelUpload(2, info)"
						  @preview="handlePreview"
				>
					<div v-if="fileList1.length < 1">
						<Icon icon="PlusOutlined"></Icon>
						<div class="ant-upload-text">上传</div>
					</div>
				</a-upload>
			</a-form-item>

			<a-form-item label="活动轮播图" name="imgUrls" extra="图片最多只能上传五张，建议比例:690*300，大小不能超过2MB">
				<a-upload :disabled="isSee" v-model:file-list="fileList" name="file" list-type="picture-card"
						  class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
						  @change="(file) => handleChange(file, 1)" multiple
						  @remove="(info) => onDelUpload(1, info)"
						  @preview="handlePreview"
				>
					<div v-if="fileList.length < 5">
						<Icon icon="PlusOutlined"></Icon>
						<div class="ant-upload-text">上传</div>
					</div>
				</a-upload>
			</a-form-item>

            <a-form-item label="活动类型" name="type"  :rules="[{required: true, message: '必填项不允许为空'}]">
                <a-radio-group :disabled="isSee" v-model:value="formState.type" name="radioGroup" @change="onClear">
                    <a-radio :value="1">线下活动</a-radio>
                    <a-radio :value="2">砍价活动</a-radio>
                    <a-radio :value="3">秒杀活动</a-radio>
                </a-radio-group>
            </a-form-item>

			<!-- <a-form-item v-if="formState.type == 2 || formState.type == 3" label="商品来源" name="source" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-radio-group :disabled="isSee" v-model:value="formState.source" name="radioGroup" @change="onClear">
				    <a-radio :value="1">卖品</a-radio>
				    <a-radio :value="2">商城</a-radio>
				</a-radio-group>
			</a-form-item> -->

            <a-form-item v-if="(formState.type == 2 || formState.type == 3)" :label="formState.source === 1 ? '活动卖品' : '活动商品'" :rules="[{required: true, message: '必填项不允许为空'}]">
                <div v-if="!isSee">
					<div v-if="formState.cinemaId">
						<snackSelect v-if="formState.source === 1" v-model:value="formState.goodsList" :selectItem="goodsList" :id="formState.cinemaId" type="radio" @change="onSnackListChange"></snackSelect>
						<chooseGoods v-if="formState.source === 2" v-model:value="formState.goodsList" :selectItem="goodsList" :id="formState.cinemaId" :isRechargeCard="0" type="radio" @change="onSnackListChange"></chooseGoods>
					</div>
					<a-button v-else disabled>请选择所属影院</a-button>
				</div>
				<a-form-item-rest v-if="goodsList && goodsList.length">
					<a-table :columns="tableColumns" :dataSource="goodsList" :pagination="false" :scroll="{x: 1000}" size="small">
						<template #bodyCell="{column, record}">
							<template v-if="column.key === 'image'">
								<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
							</template>
							<template v-if="column.key === 'card'">
								<div v-if="record.isCard">
									<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
									<div v-if="record.cardType === 1">卡次数：{{ record.cardBalanceTimes }}</div>
									<div>卡等级：{{ record.cardTitle }}</div>
								</div>
								<div v-if="record.isRechargeCard">
									<a-tag color="orange">线上会员卡</a-tag>
									<div>卡初始金额：{{ record.initAmount }} 元</div>
									<div>卡等级：{{ record.rechargeCardTitle }}</div>
								</div>
								<div v-if="!record.isRechargeCard && !record.isCard">--</div>
							</template>
							<template v-if="column.key === 'coupon'">
								<div v-if="record.isCoupon">
									<div v-if="record.ticketBatchNum">
										<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatch?.batchNo }}</span></div>
										兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatchNum }}张</span>
									</div>
									<div v-if="record.snackBatchNum">
										<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{ record.snackBatch?.batchNo }}</span></div>
										卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.snackBatchNum }}张</span>
									</div>
								</div>
								<span v-else>--</span>
							</template>
							<template v-if="column.key === 'price'">
								<div>销售价：{{ record.nowPrice }}</div>
								<div>成本价：{{ record.costPrice }}</div>
								<div>市场价：{{ record.originPrice }}</div>
							</template>
						</template>
					</a-table>
				</a-form-item-rest>
				<div class="ui-warn__box">
					<div class="ui-warn">
						商品在该活动中可参与次数由【剩余库存】控制
					</div>
					<div class="ui-warn">
						若商品包含有券，则活动库存由【券批次剩余数量】、【活动库存量】中的最小值控制
					</div>
          <div class="ui-warn">
            带有卡商品不能选择
          </div>
				</div>
            </a-form-item>

			<a-form-item v-if="formState.type === 3" label="限制用户参与活动次数" name="maxBuyNum" extra="0为不限制" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input-number :disabled="isSee" v-model:value="formState.maxBuyNum" :min="0" :precision="0" placeholder="请输入"></a-input-number>
			</a-form-item>

			<a-form-item v-if="formState.type !== 1" :label="formState.type === 2 ? '活动底价' : '秒杀价'" name="price" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input-number :disabled="isSee" v-model:value="formState.price" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元
				<div v-if="formState.type === 2" class="ui-warn__box">
					<div class="ui-warn">用户邀请好友砍到底价时才可购买</div>
				</div>
			</a-form-item>

			<div v-if="formState.type === 2">
				<a-form-item label="好友最低砍价" name="min" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number :disabled="isSee" v-model:value="formState.min" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元
				</a-form-item>
				<a-form-item label="好友最高砍价" name="max" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number :disabled="isSee" v-model:value="formState.max" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元

					<div class="ui-warn__box">
						<div class="ui-warn">好友砍掉的价格从最低砍价和最高砍价中随机</div>
					</div>
				</a-form-item>
			</div>

			<a-form-item label="活动详情" name="details" :rules="[{required: true, message: '必填项不允许为空'}]">
				<div style="min-width: 700px;position: relative;z-index: 9;">
					<Editor v-model:value="formState.details" :configSetting="{showFullScreen: false, disabled: isSee }"></Editor>
				</div>
			</a-form-item>

            <a-form-item label="排序" name="sort" extra="数字越大，排序越前，如果同级，最新创建的优先级大">
                <a-input-number :disabled="isSee" defaultValue="0" v-model:value="formState.sort" :min="0" />
            </a-form-item>

            <a-form-item label="活动有效期" name="validTime" :rules="[{required: true, message: '必选项不允许为空' }]">
                <a-range-picker :disabled="isSee" v-model:value="formState.validTime" @change="onVaildTime"/>
            </a-form-item>

            <a-form-item label="是否启用" name="isDisabled">
                <a-switch :disabled="isSee" v-model:checked="formState.isDisabled"></a-switch>
            </a-form-item>

			<a-modal v-model:visible="previewVisible" title="图片预览" :footer="null">
			    <img alt="example" style="width: 100%" :src="previewImage" />
			</a-modal>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button type="primary" :disabled="isSee" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	import moment from 'moment';
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor.vue';
    import { Icon } from '@/components/icon/icon.js';
	import snackSelect from '@/components/snackSelect/index.vue';
	import chooseGoods from '@/components/chooseGoods/chooseGoods.vue';
    import {getActivityDetail,saveActivity, updateActivity} from '@/service/modules/advert.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';

    export default {
		components: { Header, Icon, Editor, snackSelect, chooseGoods },
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
            this.getOrganizationList();
			// this.getAllCinemaList();
			if(this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
				loading: false,
                fileList: [],
				fileList1: [],
                formState: {
                    validTime: [],
                    type: 1,
					isDisabled: false,
					details: '',
					sort: 0,
					source: 2
                },
                cinemaAllList: [],
                organizationList: [],
                previewVisible: false,
				previewImage: '',
				goodsList: [],
				tableColumns: [{
					title: '名称',
					dataIndex: 'mainTitle'
				}, {
					title: '图片',
					key: 'image',
					width: 100
				}, {
					title: '剩余库存',
					dataIndex: 'stock',
					width: 120
				}, {
					title:'价格信息',
					key: 'price',
					width: 120
				}, {
					title: '卡信息',
					key: 'card',
					width: 250
				}, {
					title: '券信息',
					key: 'coupon',
					width: 250
				}]
			}
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
				try {
					this.loading = true;
					let {data} = await getActivityDetail({
					    id: this.id,
					})
					this.loading = false;
					if (data.imgUrl) {
						data.imgUrl = data.imgUrl.split(',');
						this.fileList1 = data.imgUrl.map((item, index) => {
							return {
								url: item,
								uid: index,
								name: item
							}
						})
					}
					if (data.imgUrls) {
						data.imgUrls = data.imgUrls.split(',');
						this.fileList = data.imgUrls.map((item, index) => {
							return {
								url: item,
								uid: index,
								name: item
							}
						})
					}
					if (data.goodsId) {
						data.goodsList = [data.goodsId];
						this.goodsList = [data.storeGoodsStock];
					}
					if (data.type === 1) {
						data.source = 2;
					}
					if (data.type === 2) {
						data.conditions = JSON.parse(data.conditions);
						data.min = data.conditions.min;
						data.max = data.conditions.max;
					}
					delete data.conditions;
					this.formState = data;
					this.formState.isDisabled = this.formState.isDisabled == 0 ? true : false;
					this.formState.validTime = [moment(this.formState.startTime*1000), moment(this.formState.endTime*1000)];
					this.getAllCinemaList(data.organizationId, false);
				} catch(e) {
					this.loading = false;
				}
            },
			onSubmit() {
                this.$refs.formRef.validateFields().then(async () => {
                    let startTime = moment(this.formState.validTime[0].startOf('day')).unix();
                    let endTime = moment(this.formState.validTime[1].endOf('day')).unix();
                    if(!this.fileList1.length) {
                        this.$message.warn('请上传活动列表图');
                        return;
                    }
                    if(!startTime || !endTime) {
                        this.$message.warn('请选择有效期');
                        return;
                    }
                    if(this.formState.type !== 1 && (!this.formState.goodsList || !this.formState.goodsList.length)) {
                        this.$message.warn('请选择活动卖品');
						return;
                    }
                    const postData = JSON.parse(JSON.stringify(this.formState));
                    if(postData.goodsList && postData.goodsList.length) {
                        postData.goodsId = postData.goodsList.join(',');
                    }
					if (postData.type === 2) {
						postData.conditions = {
							min: postData.min,
							max: postData.max
						}
					}
					if (postData.type === 1) {
						postData.source = 2;
					}
					delete postData.min;
					delete postData.max;
					delete postData.goodsList;
                    delete postData.validTime;
                    postData.startTime = startTime;
                    postData.endTime = endTime;
                    postData.isDisabled = this.formState.isDisabled == true ? 0 : 1;
                    postData.imgUrl = postData.imgUrl.length ? postData.imgUrl.join(',') : '';
					postData.imgUrls = (postData.imgUrls && postData.imgUrls.length) ? postData.imgUrls.join(',') : '';
                    let res;
					this.loading = true;
					try {
						if(this.id){
						    postData.id = this.id;
						    res = await updateActivity(postData);
						}else{
						    res = await saveActivity(postData);
						}
						this.loading = false;
						if(res.code == 200){
						    this.$message.success('操作成功');
						    this.onBack();
						}
					} catch(e) {
						this.loading = false;
					}
				})
            },
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info, type) {
				if (!info.file.status) {
					if (type === 1) {
						this.fileList.splice(this.fileList.length - 1, 1);
					}
					if (type === 2) {
						this.fileList1.splice(this.fileList1.length - 1, 1);
					}
				}
				if (info.file.status === 'done') {
					// this.fileList = info.fileList;
					if (type === 1) {
						this.formState.imgUrls = [];
					}
					if (type === 2) {
						this.formState.imgUrl = [];
					}
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							if (type === 1) {
								this.formState.imgUrls.push(file.response.data.imgUrl);
							}
							if (type === 2) {
								this.formState.imgUrl.push(file.response.data.imgUrl);
							}
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			onDelUpload(type, info) {
				if (type === 1) {
					this.formState.imgUrls = [];
					this.fileList.forEach(item => {
						if (item.url && item.url !== info.url) {
							this.formState.imgUrls.push(item.url);
						}
					});
				}
				if (type === 2) {
					this.formState.imgUrl = [];
					this.fileList1 = [];
				}
				return true;
			},
            onVaildTime(value){
                this.formState.validTime = value;
            },
			onSnackListChange(data) {
				this.goodsList = data;
			},
            async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId, isClear) {
				if (isClear) {
					this.cinemaAllList = [];
					this.formState.cinemaId = undefined;
					this.onClear();
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
            handlePreview(file) {
                this.previewImage = file.url || file.thumbUrl;
                this.previewVisible = true;
            },
            handleCancel() {
                this.previewVisible = false;
            },
			onClear() {
				this.formState.goodsList = [];
				this.formState.price = undefined;
				this.goodsList = [];
			},
		}
	}
</script>

<style scoped>
	.ui-warn__box {
		margin: 10px 0 20px 0;
		padding: 10px 20px;
		background-color: antiquewhite;
		border-radius: 10px;
	}
	.ui-warn {
		color: #737272;
	}
</style>
